jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });
});

const menuMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".util-wrapper",
    desktopMethod: "insertBefore",
    breakpoint: 1024.98,
}).watch();

var detailImgSlider = new Swiper('.detail-img .swiper-container', {
    spaceBetween: 40,
    navigation: {
        prevEl: '.detail-img .swiper-prev',
        nextEl: '.detail-img .swiper-next'
    },
})

var newsSlider = new Swiper('.news-slider .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 90,
    navigation: {
        prevEl: '.news-slider .swiper-prev',
        nextEl: '.news-slider .swiper-next'
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})

var productSlider = new Swiper('.product-slider .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: '.product-slider .swiper-prev',
        nextEl: '.product-slider .swiper-next'
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})

var otherSlider = new Swiper('.other-slider .swiper-container', {
    slidesPerView: 2,
    spaceBetween: 30,
    navigation: {
        prevEl: '.other-slider .swiper-prev',
        nextEl: '.other-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})

var businessSlider = new Swiper('.business-other-slider .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: '.business-other-slider .swiper-prev',
        nextEl: '.business-other-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})

var yearDotSlider = new Swiper('.year-dot-slider .swiper-container', {
    spaceBetween: 20,
    slidesPerView: 11,
    breakpoints: {
        1280: {
            slidesPerView: 8,
        },
        1024: {
            slidesPerView: 6,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 15,
        }
    }
})

var yearContentSlider = new Swiper('.year-content-slider .swiper-container', {
    spaceBetween: 20,
    thumbs: {
        swiper: yearDotSlider
    },
    navigation: {
        prevEl: '.year-dot-slider .swiper-prev',
        nextEl: '.year-dot-slider .swiper-next'
    }
})

var partnerSlider = new Swiper('.partner-slider .swiper-container', {
    spaceBetween: 20,
    navigation: {
        prevEl: '.partner-slider .swiper-prev',
        nextEl: '.partner-slider .swiper-next'
    }
})

var homeBusinessSlider = new Swiper('.home-business-slider .swiper-container', {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: '.home-business-slider .swiper-prev',
        nextEl: '.home-business-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.25,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})

var homeProductSlider = new Swiper('.home-product-slider .swiper-container', {
    slidesPerView: 3,
    slidesPerColumn: 2,
    spaceBetween: 30,
    navigation: {
        prevEl: '.home-product-slider .swiper-prev',
        nextEl: '.home-product-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            slidesPerColumn: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.25,
            slidesPerColumn: 1,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.25,
            slidesPerColumn: 1,
            spaceBetween: 15,
        }
    }
})

var homeBusinessSlider = new Swiper('.home-service-slider .swiper-container', {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: '.home-service-slider .swiper-prev',
        nextEl: '.home-service-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.25,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})